var stickyHeader = true;
// var flyoutNav = false;
// var fullscreenNav = false;
// var inlineNav = false;
// var hoverSensitiveNav = false;
// var megaMenu = false;

// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video

var countUpStats = true;
// var countUpStatsDuration = 3000;

// var homeQuickGivingPreFooter = false;
// var transformHomepageDonationAmountImages = false;

// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var subsiteTitleNotClickable = true;

// var sidebarCards = false; // Make sure to set a card layout prefab for '.associatedRelatedPosts .associatedListing' and a card prefab to '.sidebarCard'

// var visualAppealsLoadMore = false;

$(document).ready(function () {
  $("<div class='divider'></div>").insertAfter(".homeIntroWrapper");
  $(".homefeaturecategory-homeboxquickgiving").insertAfter(".homefeaturecategory-homeboximpactstats");
  $(".homeFeed.PreparingyourgrantapplicationFeed").insertBefore(".homefeaturecategory-homeboximpactstats");
  $(".homeFeed.CasestudiesFeed").insertAfter(".homeFeed.PreparingyourgrantapplicationFeed");
  $('<a href="/pages/category/challenge-events" class="cta-button view-all">View all challenge events</a>').appendTo('.ChallengeeventsFeed');
  $('<a href="#" class="cta-button view-all">View all</a>').appendTo('.homeFeedBox5');
});
